// Copyright 2024 The LUCI Authors.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//      http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import { Fragment } from 'react';

import { Variant } from '@/proto/go.chromium.org/luci/resultdb/proto/v1/common.pb';

interface VariantLineProps {
  readonly variant: Variant;
}

export function VariantLine({ variant }: VariantLineProps) {
  return (
    <>
      {Object.entries(variant.def).map(([k, v]) => (
        <Fragment key={k}>
          <span style={{ color: '#737373' }}>{k}: </span>
          <span>{v} </span>
        </Fragment>
      ))}
    </>
  );
}
